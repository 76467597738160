<template>
  <v-card-title>
    <slot name="prepend"></slot>
    <b-col>
      <b-form-group id="search-group" label-for="search-input">
        <FormInput
          type="text"
          :i18n="{
            label: 'LABELS.SEARCH',
            placeholder: placeholder || $t('LABELS.SEARCH')
          }"
          :validateState="{}"
          v-model="searchText"
          @keyup.enter="$emit('do-search')"
        ></FormInput>
      </b-form-group>
    </b-col>

    <!-- <v-text-field
      v-model="searchText"
      :label="$t('LABELS.SEARCH')"
      v-on:keyup.enter="$emit('do-search')"
      single-line
      hide-details
    ></v-text-field> -->
    <div v-if="!hideActions">
      <v-tooltip color="primary" bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            class="mx-2"
            fab
            dark
            x-small
            color="primary"
            v-bind="attrs"
            v-on="on"
            @click="$emit('do-search')"
          >
            <v-icon dark>search</v-icon>
          </v-btn>
        </template>
        <span>{{ $t("LABELS.SEARCH") }}</span>
      </v-tooltip>
      <v-tooltip color="red" bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            class="mx-2"
            fab
            dark
            x-small
            color="red"
            @click="$emit('do-clear')"
            v-bind="attrs"
            v-on="on"
          >
            <v-icon dark>mdi-close</v-icon>
          </v-btn>
        </template>
        <span>{{ $t("LABELS.CLEAR") }}</span>
      </v-tooltip>
    </div>
  </v-card-title>
</template>

<script>
import FormInput from "@/view/content/forms/components/FormInput.vue";

//General
export default {
  name: "SearchTools",
  components: {
    FormInput
  },
  inheritAttrs: false,
  props: {
    placeholder: {
      type: String,
      default: null
    },
    searchQuery: {
      type: String,
      default: ""
    },
    hideActions: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {};
  },
  watch: {},
  beforeMount() {},
  computed: {
    searchText: {
      get: function() {
        return this.searchQuery;
      },
      set: function(newValue) {
        this.$emit("update:searchQuery", newValue);
      }
    }
  },
  methods: {}
};
</script>
