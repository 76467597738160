<template>
  <div>
    <b-card no-body class="card-custom" header-tag="header" footer-tag="footer">
      <template v-slot:header>
        <div class="card-title">
          <h3 class="card-label">
            {{ $t("MENU.ITEM.SETTINGS.APP_SETTINGS") }}
            <i class="mr-2"></i>
            <small class="">{{ $t("LABELS.LIST") }}</small>
          </h3>
        </div>
        <div class="card-toolbar">
          <button
            class="btn btn-success font-weight-bolder"
            @click="handleClickSave"
          >
            <i class="fa fa-check"></i>
            {{ $t("LABELS.SAVE") }}
          </button>
        </div>
      </template>
      <v-card>
        <SearchTools
          :searchQuery.sync="searchQuery"
          @do-search="fetchAppSettings"
          @do-clear="searchQuery = null"
        >
          <template v-slot:prepend> </template>
        </SearchTools>
        <v-form class="px-8">
          <div v-for="(gi, index) in groupedItems" :key="index">
            <h2>{{ index }}</h2>
            <v-divider></v-divider>
            <AppSettingItem
              v-for="item in gi"
              :key="item.id"
              :item="item"
              v-model="item.value"
              @input="handleSettingInput(item.id, $event)"
            ></AppSettingItem>
          </div>
          <v-overlay :value="isLoadingAppSetting">
            <v-progress-circular indeterminate size="64"></v-progress-circular>
          </v-overlay>
        </v-form>

        <div class="card-toolbar px-8 py-4 text-right">
          <button
            class="btn btn-success font-weight-bolder"
            @click="handleClickSave"
          >
            <i class="fa fa-check"></i>
            {{ $t("LABELS.SAVE") }}
          </button>
        </div>
      </v-card>
    </b-card>
  </div>
</template>

<script>
import _ from "lodash";

//General
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";

import { mapGetters } from "vuex";
import FormSelect from "@/view/content/forms/components/FormSelect.vue";
import SearchTools from "@/core/components/table/SearchTools.vue";

import { permissionMixin } from "@/core/mixins/permissionMixin.js";
import {
  FETCH_APP_SETTINGS,
  UPDATE_APP_SETTINGS
} from "../store/app-setting.module";
import AppSettingItem from "../components/AppSettingItem";

export default {
  mixins: [permissionMixin],
  components: {
    FormSelect,
    SearchTools,
    AppSettingItem
  },
  data() {
    return {
      searchQuery: null,
      items: [],
      form: {
        settings: []
      }
    };
  },
  computed: {
    ...mapGetters(["isLoadingAppSetting"]),
    filteredItems() {
      return !this.searchQuery
        ? this.items
        : this.items.filter(
            x =>
              x.name.toLowerCase().includes(this.searchQuery.toLowerCase()) ||
              x.value.toLowerCase().includes(this.searchQuery.toLowerCase())
          );
    },
    groupedItems() {
      return _.groupBy(this.filteredItems, "appSettingGroupName");
    }
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Основни настройки", route: { name: "list-app-settings" } },
      { title: "Списък основни настройки" }
    ]);
    this.fetchAppSettings();
  },
  methods: {
    fetchAppSettings() {
      this.$store.dispatch(FETCH_APP_SETTINGS).then(({ data }) => {
        this.items = data;
        this.items.forEach((item, index) => {
          this.$set(this.form.settings, index, {
            id: item.id,
            value: item.value
          });
        });
      });
    },
    handleSettingInput(id, v) {
      let setting = this.form.settings.find(x => x.id === id);
      setting.value = v;
    },
    handleClickSave() {
      this.$store
        .dispatch(UPDATE_APP_SETTINGS, this.form)
        .then(() => {
          this.$notify({
            group: "notify",
            type: "success",
            title: "<i class='flaticon2-checkmark'></i> Success"
          });
        })
        .catch(response => {
          this.errors = response?.data?.errors;
          this.$notify({
            group: "notify",
            type: "error",
            title: this.$t("ALERTS.ERROR"),
            text: response?.data?.message
          });
        });
    }
  }
};
</script>
