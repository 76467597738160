<template>
  <div>
    <b-form-row>
      <b-col md="12">
        <b-form-group
          v-bind:label="item.name"
          v-bind:label-for="'value_' + item.id"
        >
          <div class="input-group">
            <FormInput
              :id="'value_' + item.id"
              :type="item.appSettingTypeName"
              :name="'value_' + item.id"
              :value="item.value"
              @input="handleInput"
            >
            </FormInput>
          </div>
        </b-form-group>
      </b-col>
    </b-form-row>
  </div>
</template>

<script>
import FormInput from "@/view/content/forms/components/FormInput.vue";

export default {
  name: "AppSettingItem",
  components: { FormInput },
  props: {
    item: Object
  },
  methods: {
    handleInput(value) {
      this.$emit("input", value);
    }
  }
};
</script>
